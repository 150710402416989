import { AuthState } from '@core/store/auth/auth.state';
import { CategoriesState } from '@core/store/category/category.state';
import { CompaniesState } from '@core/store/companies/companies.state';
import { ConversationsState } from '@core/store/conversations/conversations.state';
import { CountriesState } from '@core/store/countries/countries.state';
import { FavoritesState } from '@core/store/favorites/favorites.state';

import { JobsState } from '@core/store/jobs/jobs.state';
import { MyPublicationsState } from '@core/store/my-publications/my-publications.state';
import { NotesState } from '@core/store/notes/notes.state';
import { NotificationState } from '@core/store/notification/notication.state';
import { PaymentsState } from '@core/store/payments/payments.state';
import { PostulationState } from '@core/store/postulations/postulations.state';
import { ProfileState } from '@core/store/profile/profile.state';
import { PublicationsState } from '@core/store/publication/publication.state';
import { ReservationsState } from '@core/store/reservations/reservations.state';
import { ResumeState } from '@core/store/resumes/resume.state';
import { ScheduleState } from '@core/store/schedules/schedules.state';
import { SearchState } from '@core/store/searches/search.state';
import { SessionsState } from '@core/store/sessions/sessions.state';
import { SidebarState } from '@core/store/sidebar/sidebar.state';
import { TriberState } from '@core/store/triber/triber.state';

export const appState = [
  AuthState,
  CategoriesState,
  NotificationState,
  ProfileState,
  TriberState,
  PublicationsState,
  SidebarState,
  ReservationsState,
  SessionsState,
  NotesState,
  FavoritesState,
  ResumeState,
  MyPublicationsState,
  ScheduleState,
  SearchState,
  ConversationsState,
  JobsState,
  CountriesState,
  CompaniesState,
  PostulationState,
  PaymentsState,
];
